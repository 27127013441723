<template>
  <div>
    <!-- <v-progress-linear class="mt-2" indeterminate v-if="loading" color="primary darken-2"></v-progress-linear> -->
    <v-row class="ma-1">
      <v-col cols="12" class="pa-0">
        <v-autocomplete clearable filled dense :label="$t('message.projects.project')" :items="listOfProjects" hide-details v-model="projectId" item-text="projectName" item-value="id" @change="onProjectSelect"></v-autocomplete>
      </v-col>
    </v-row>
    <data-list :payload="payload" :projectId="projectId" :module="MODULE" :model="modelObj" :key="reInit" moduleTitle="message.checklist.hmsKs" ref="checklistList"></data-list>
    <v-dialog v-model="dialog" hide-overlay transition="dialog-bottom-transition" persistent fullscreen>
      <v-card class="pa-0 ma-0">
        <v-app-bar color="primary" dense dark fixed>
          <v-toolbar-title>{{ $t(`message.checklist.${checklistId ? 'updateChecklist' : 'addCheckList' }`) }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn color="primary" elevation="0" fab small dark @click="closeDialogHandler">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-app-bar>
        <v-card-text class="pa-0 ma-0" style="position:absolute; top:50px">
          <form-render :formId="formId" :module="MODULE" v-if="dialog" :checklistId="checklistId" :formName="formName" :checklistName="checklistTitle" :selectedItemId="selectedItemId" :selectedProjectId="projectId" :projectChecklistFormData="projectChecklistFormData" :checklistEmail="checklistEmail" :canSendEmail="canSendEmail" :dataGridImportValue="dataGridImportValue"></form-render>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import PermissionsMixin from '@/mixins/permissions'
import projectMixin from '@/mixins/projectMixin'
import { checklistApi } from '../../plugins/axios_settings'
export default {
  mixins: [projectMixin, PermissionsMixin],
  data () {
    return {
      modelObj: {},
      reInit: 0,
      payload: {},
      dialog: false,
      formId: '',
      formName: '',
      checklistId: '',
      loading: false,
      selectedItemId: 0,
      checklistTitle: '',
      MODULE: 'global',
      projectId: 0,
      listOfProjects: [],
      projectChecklistFormData: null,
      checklistEmail: '',
      canSendEmail: '',
      projectIds: null,
      getChecklist: null,
      dataGridImportValue: null
    }
  },
  components: {
    'data-list': () => import('@/components/checklist/List'),
    'form-render': () => import('@/components/checklist/Actions')
  },
  mounted () {
    this.$root.$on('dialog', data => {
      this.dialog = true
    })
    this.getAllProjects()
    this.$eventBus.$on('checklistDialog', (data) => {
      this.formId = data.form_id
      this.checklistId = null
      this.formName = data.form_name
      this.selectedItemId = data.itemId
      this.projectChecklistFormData = data.projectChecklistFormData
      this.dialog = true
      this.checklistTitle = ''
      this.checklistEmail = data.checklist_recipients
      this.canSendEmail = data.can_send_email
      this.dataGridImportValue = data.dataGridImportValue
    })
    this.$eventBus.$on('updatechecklistDialog', (data) => {
      this.dialog = true
      this.checklistId = data.checklist_id || null
      this.formId = data.form_id
      this.formName = data.form_name
      this.selectedItemId = data.itemId
      this.checklistTitle = data.title
      this.checklistEmail = data.checklist_recipients
      this.canSendEmail = data.can_send_email
    })
    this.$eventBus.$on('closeChecklistDialog', () => {
      this.dialog = false
      this.$eventBus.$emit('clearChecklist', this.projectId)
    })
    this.$eventBus.$on('setChecklistId', (checklistId) => {
      this.checklistId = checklistId
    })
    this.$eventBus.$on('showLoading', () => {
      this.loading = true
    })
    this.$eventBus.$on('hideLoading', () => {
      this.loading = false
    })
  },
  methods: {
    closeDialogHandler () {
      this.dialog = false
      this.$eventBus.$emit('clearChecklist')
    },
    getAllProjects () {
      this.getSetListItems(`projects/get_as_simplified_for_pwa?filter=${this.isAdmin() ? 3 : 1}`, 'listOfProjects') // 4 is allactive, 2 is myactive
    },
    getSetListItems (url, listName) {
      this.$api.execute('get', url).then((response) => {
        if (response.data) {
          if (listName === 'listOfProjects') {
            const list = this.$formatter.cloneVariable(response.data)
            const timerProject = list.find(x => x.number === 'TM001')
            if (timerProject) {
              const indexOf = list.indexOf(timerProject)
              list.splice(indexOf, 1)
            }
            list.forEach(project => {
              project.projectName = this.setProjectname(project)
            })
            this[listName] = list
          } else this[listName] = response.data
        }
      })
    },
    onProjectSelect () {
      this.MODULE = this.projectId ? 'project' : 'global'
      setTimeout(() => {
        this.$refs.checklistList.getListOfChecklists(this.projectId || null, true)
        this.$refs.checklistList.getListHandler(this.projectId || null, true)
      }, 100)
    },
    readCSVFileAndSetData () {
      var regex = /^([a-zA-Z0-9\s_\\.\-:])+(.csv|.txt)$/
      if (regex.test(this.csvFile.name.toLowerCase())) {
        if (typeof (FileReader) !== 'undefined') {
          this.importCsvDialog = false
          /* get the form properties of checklist */
          this.getDataGridFormProperties()
          this.saveWithoutImport = false
        } else alert('This browser does not support HTML5.')
      } else {
        alert('Please upload a valid CSV file.')
      }
    },
    getDataGridFormProperties () {
      if (this.selectedChecklistTemplate) {
        checklistApi
          .get(`formproperties/get_by_form/${this.selectedChecklistTemplate.checklist_template_api_id}`)
          .then((response) => {
            if (response && response.data) this.datagridFormProperties = response.data.filter(x => x.type === 18) // get only datagrids
          })
      }
    }
  },
  beforeDestroy () {
    this.$eventBus.$off('checklistDialog')
    this.$eventBus.$off('updatechecklistDialog')
    this.$eventBus.$off('closeChecklistDialog')
    this.$eventBus.$off('setChecklistId')
    this.$eventBus.$off('hideLoading')
    this.$eventBus.$off('showLoading')
  }
}
</script>
